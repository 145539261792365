import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Storage, { LOGIN_USER } from '../services/Storage'
import { LoginResponseDto } from '../services/Swagger/Services'
function Root() {
  const user: LoginResponseDto = Storage.getItem(LOGIN_USER)
  if (!user) {
    window.location.href = '/auth/login'
  }
  return (
    <div className='AppMain'>
      <Outlet />
      <Box className='cpr'>
        <p>Copyright @ Cykelmakker 2023</p>
      </Box>
    </div>
  )
}

export default Root

export function RootChat() {
  return (
    <div className='AppMain'>
      <Outlet />
    </div>
  )
}