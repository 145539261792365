import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Formik, FormikProps, getIn } from 'formik'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AppBar, FileInput, Icon, Loading } from '../../components'
import { updateSnackbarState } from '../../redux'
import Storage, { USER_PROFILE } from '../../services/Storage'
import {
  CustomerCasePostPut,
  ECaseStatus,
  ECaseReason,
  EServicePlace,
  EMainContact,
  CustomerProfile,
  AccountApi,
} from '../../services/Swagger/Services'
import { CustomApi } from '../../services/custom_api/custom-api'
import { Colors } from '../../theme'
import { useAddTicket } from './useAddTicket'
import { getBase64 } from '../../services/Helper'
import { usePlacesWidget } from 'react-google-autocomplete'
import { MAP_AUTO_COMPLETE_CONFIG } from '../../services/enum/enum'
import { useTranslation } from 'react-i18next'
import yup from '../../services/Translations/yup'
import Codes from '../../assets/codes.json'

const ticketSchema = yup.object({
  userProductId: yup.number().min(1).required(),
  subject: yup.string().required(),
  description: yup.string().required(),
  repairShopId: yup.number().when('servicePlace', {
    is: EServicePlace.AtRepairshop,
    then: (schema) => schema.min(1).required(),
    otherwise: (schema) => schema.min(0),
  }),
  contact: yup.object().shape({
    phoneNumber: yup.string().required(),
  }),
})

const getListFiles = async (files: FileList) => {
  const fileBase64: any[] = Array.from(files)
  return await Promise.all(
    fileBase64.map(async (file) => {
      return await getBase64(file)
    }),
  )
}
function Add() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user: CustomerProfile = Storage.getItem(USER_PROFILE)
  const { products, errorTypes, repairShops, isLoading, getServicePlace, isOnlineProduct } =
    useAddTicket()
  const dispatch = useDispatch()
  const [isSubmit, setIsSubmit] = useState(false)
  const [ticket, setTicket] = useState<CustomerCasePostPut>({
    userProductId: 0,
    status: ECaseStatus.UnSent,
    reason: ECaseReason.Defective,
    servicePlace: EServicePlace.AtRepairshop,
    repairShopId: undefined,
    subject: '',
    description: '',
    photos: [],
    contact: {
      mainContact: user.mainContact,
      address: user.address,
      address2: user.address2,
      city: user.city,
      postalCode: user.postalCode,
      country: user.country,
      phoneNumber: user.phoneNumber,
    },
    errorType: {
      errorTypeId: 0,
      name: '',
      active: true,
    },
  })
  const formRef = useRef<FormikProps<CustomerCasePostPut>>(null)
  const { ref } = usePlacesWidget({
    ...MAP_AUTO_COMPLETE_CONFIG,
    onPlaceSelected: (place) => {
      if (place.address_components && place.formatted_address && place.geometry) {
        const city = place.address_components?.find((add: any) =>
          add.types.includes('administrative_area_level_1'),
        )
        const country = place.address_components?.find((add: any) => add.types.includes('country'))
        formRef.current?.setFieldValue('contact.city', city?.long_name)
        formRef.current?.setFieldValue('contact.country', country?.short_name)
        formRef.current?.setFieldValue('contact.address', place.formatted_address)
        formRef.current?.setFieldValue('lat', place.geometry.location.lat())
        formRef.current?.setFieldValue('lng', place.geometry.location.lng())
      } else {
        dispatch(
          updateSnackbarState({
            visible: true,
            message:
              'Oop, We could not find your address on the map. Make sure the address is correct',
            type: 'error',
          } as any),
        )
      }
    },
  })
  const onSubmit = async (values: CustomerCasePostPut) => {
    setIsSubmit(true)
    try {
      await new CustomApi().apiCustomerCasesFormPost(
        values.userProductId,
        values.status,
        values.reason,
        values.servicePlace,
        values.subject,
        values.description,
        values.contact?.mainContact,
        values.repairShopId,
        [],
        values.contact?.address,
        '',
        values.contact?.city,
        values.contact?.postalCode,
        values.contact?.country,
        values.contact?.phoneNumber,
        values.errorType?.errorTypeId,
        values.errorType?.name,
        values.errorType?.active,
        '',
        values.files,
      )
      const response = await new AccountApi().apiAccountsCustomerProfileGet()
      Storage.setItem(USER_PROFILE, response.data)
      navigate('/my-tickets')
    } catch (ex) {
      dispatch(
        updateSnackbarState({
          visible: true,
          message: 'Error when add new ticket, please check your ticket information',
          type: 'error',
        } as any),
      )
    }
    setIsSubmit(false)
  }
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('createATicket')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'md'}>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            my: 6,
          }}
        >
          <Box sx={{ width: 30 }}>
            <Link to='/'>
              <Icon name='icon-arrow-left' color='white' />
            </Link>
          </Box>
          <Box>
            <Typography variant='h3'>{t('createATicket')}</Typography>
          </Box>
          <Box sx={{ width: 30 }}></Box>
        </Stack>
        {isLoading && <Loading />}
        {!isLoading && (
          <Formik
            innerRef={formRef}
            initialValues={ticket}
            enableReinitialize={true}
            validationSchema={ticketSchema}
            onSubmit={onSubmit}
          >
            {({ handleChange, setFieldValue, handleSubmit, values, errors, isValid }) => (
              <>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('selectABike')}
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    value={values.userProductId}
                    onChange={(event) => {
                      const newId = Number(event.target.value)
                      setFieldValue('userProductId', newId)
                      if (!isOnlineProduct(newId)) {
                        setFieldValue('placeOfService', EServicePlace.AtRepairshop)
                        setFieldValue('repairShopId', repairShops[0].repairshopId)
                      }
                    }}
                  >
                    {products.map((product) => (
                      <MenuItem key={product.userProductId} value={product.userProductId}>
                        {product.userProductName}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errors.userProductId && (
                    <Typography className='textError'>{errors.userProductId}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('errorType')}
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    value={values.errorType?.errorTypeId}
                    onChange={(value) => {
                      setFieldValue('errorType.errorTypeId', value.target.value)
                    }}
                  >
                    {errorTypes.map((error) => (
                      <MenuItem key={error.errorTypeId} value={error.errorTypeId}>
                        {error.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('subject')}
                  </Typography>
                  <TextField
                    value={values.subject}
                    onChange={handleChange('subject')}
                    fullWidth
                    variant='outlined'
                  />
                  {errors.subject && (
                    <Typography className='textError'>{errors.subject}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('description')}
                  </Typography>
                  <TextField
                    value={values.description}
                    multiline
                    onChange={handleChange('description')}
                    fullWidth
                    variant='outlined'
                  />
                  {errors.description && (
                    <Typography className='textError'>{errors.description}</Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <FileInput
                    label={t('bikePhotos')}
                    multiple={true}
                    accept={'image/*, video/*'}
                    onChange={async (files) => {
                      if (files) {
                        setFieldValue('files', Array.from(files))
                        // setFieldValue('files', await getListFiles(files))
                      } else {
                        setFieldValue('files', [])
                      }
                    }}
                  />
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption'>{t('placeOfService')}</Typography>
                  <TextField
                    select
                    fullWidth
                    value={values.servicePlace}
                    onChange={(event) => {
                      setFieldValue('servicePlace', event.target.value)
                      if (event.target.value === EServicePlace.AtRepairshop) {
                        setTicket({
                          ...values,
                          repairShopId: repairShops[0].repairshopId,
                        })
                      }
                    }}
                  >
                    {getServicePlace(isOnlineProduct(values.userProductId)).map((item) => {
                      return (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Box>
                {values.servicePlace === EServicePlace.AtRepairshop && (
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption' className='required'>
                      {t('repairShop')}
                    </Typography>
                    <Autocomplete
                      fullWidth
                      onChange={(event, value) => {
                        setFieldValue('repairShopId', value?.repairshopId)
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.repairshopId}>
                            {`${option.name}`}
                          </li>
                        )
                      }}
                      getOptionLabel={(option) => {
                        return option.name ?? ''
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.repairshopId === value.repairshopId
                      }
                      options={repairShops}
                      id='id'
                      renderInput={(params) => <TextField {...params} />}
                    />
                    {errors.repairShopId && (
                      <Typography className='textError'>{errors.repairShopId}</Typography>
                    )}
                  </Box>
                )}
                <Typography variant='h3' color={Colors.primary} sx={{ mb: 6, mt: 12 }}>
                  {t('yourContact')}
                </Typography>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('address')}
                  </Typography>
                  <TextField
                    value={values.contact?.address}
                    onChange={handleChange('contact.address')}
                    inputRef={ref}
                    fullWidth
                    variant='outlined'
                  />
                </Box>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Box sx={{ mb: 6 }}>
                      <Typography variant='caption'>{t('postalCode')}</Typography>
                      <TextField
                        value={values.contact?.postalCode}
                        onChange={(event) => {
                          setFieldValue('contact.postalCode', event.target.value)
                          const findIndex = Codes.findIndex((x) => x.code == event.target.value)
                          if (findIndex != -1) {
                            setFieldValue('contact.city', Codes[findIndex].city)
                          } else {
                            setFieldValue('contact.city', '')
                          }
                        }}
                        fullWidth
                        variant='outlined'
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mb: 6 }}>
                      <Typography variant='caption'>{t('city')}</Typography>
                      <TextField
                        value={values.contact?.city}
                        onChange={handleChange('contact.city')}
                        fullWidth
                        variant='outlined'
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption' className='required'>
                    {t('phoneNumber')}
                  </Typography>
                  <TextField
                    value={values.contact?.phoneNumber}
                    onChange={handleChange('contact.phoneNumber')}
                    fullWidth
                    variant='outlined'
                  />
                  {getIn(errors, 'contact.phoneNumber') && (
                    <Typography className='textError'>
                      {getIn(errors, 'contact.phoneNumber')}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mb: 6 }}>
                  <Typography variant='caption'>{t('howWeWillContactYou')}</Typography>
                  <TextField
                    select
                    fullWidth
                    value={values.contact?.mainContact}
                    onChange={handleChange('contact.mainContact')}
                  >
                    {Object.values(EMainContact).map((contact) => (
                      <MenuItem key={contact} value={contact}>
                        {t(`contactTypes.${contact}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box sx={{ mb: 8 }}>
                  <Button
                    fullWidth
                    disabled={isSubmit || !isValid}
                    variant='contained'
                    onClick={() => handleSubmit()}
                  >
                    {t('createATicket')}
                  </Button>
                </Box>
              </>
            )}
          </Formik>
        )}
      </Container>
    </Box>
  )
}

export default Add
