import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { AppBar, Icon, Loading } from '../../components'
import { DATE_FORMAT } from '../../components/DatePicker/DatePicker'
import { UserProductDetails, UserProductApi } from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import 'react-image-lightbox/style.css'
import { useTranslation } from 'react-i18next'
import { ticketClasses } from '../Ticket/List'
import { useState } from 'react'

function Detail() {
  const pdfImg = require('../../assets/images/PDF.png')
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const caseId = searchParams.get('id')
  const { data, isLoading } = useQuery<UserProductDetails & { userProduct: any }>(
    [`/api/customerCases/details/${caseId}`],
    async () => {
      const res = await new UserProductApi().apiUserProductsDetailsByUserProductIdGet(
        Number(searchParams.get('id')),
      )
      return res.data as any
    },
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const deleteBike = async (id: number) => {
    const text = `${t('deteleWarningTitle')} \n ${t('deteleWarningDesc')}`
    if (confirm(text) == true) {
      await new UserProductApi().apiUserProductsByUserProductIdDelete(id)
      navigate('/my-bikes')
    }
  }
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('bikeInfo')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'lg'}>
        <Box justifyContent={'space-between'} display='flex' alignItems={'center'} sx={{ mt: 6 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/'>Cykelmakker</Link>
            <Link to='/my-bikes'>{t('myBikes')}</Link>
            <Typography color='text.primary'>{data?.productName}</Typography>
          </Breadcrumbs>
          <Link to='/create-ticket'>
            <Button fullWidth variant='contained' size='small'>
              {t('createATicket')}
            </Button>
          </Link>
        </Box>
        {isLoading && <Loading />}
        {!isLoading && (
          <Box>
            <Stack
              className='borderBottom'
              direction={'row'}
              alignItems='center'
              sx={{ my: 6 }}
              spacing={4}
            >
              <Icon name='icon-info-circle' color={Colors.primary} />
              <Typography color={Colors.primary} variant='h3'>
                {t('bikeInfo')}
              </Typography>
              <Box style={{ marginLeft: 'auto' }}>
                <IconButton aria-label='delete' onClick={handleClick}>
                  <Icon name='icon-more' color={Colors.primary} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(`/create-bike?id=${data?.userProductId}`)
                    }}
                  >
                    {t('edit')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      deleteBike(data?.userProductId ?? 0)
                    }}
                  >
                    {t('delete')}
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
            <Grid container columns={12} marginTop={0} spacing={4}>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('model')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.productName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('frameNumber')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.frameNumber}</Typography>
              </Grid>
              {data?.serialNumber && (
                <>
                  <Grid item xs={3}>
                    <Typography variant='body2'>{t('batteryNumber')}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography align='right'>{data?.serialNumber ?? '-'}</Typography>
                  </Grid>
                </>
              )}
              {/* <Grid item xs={3}>
                <Typography variant='body2'>{t('billNumber')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.billNumber}</Typography>
              </Grid> */}
              <Grid item xs={3}>
                <Typography variant='body2'>{t('purchasedDate')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>
                  {moment.unix(data?.billDate ?? 0).format(DATE_FORMAT)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body2'>{t('purchasedPlace')}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography align='right'>{data?.purchasedPlace?.address}</Typography>
              </Grid>
            </Grid>
            <Grid container columns={12} marginTop={0} spacing={4}>
              {data?.framePhotos
                ?.concat(data?.serialPhotos ?? [])
                ?.concat(data?.billPhoto ?? '')
                .filter((e) => !e.includes('.pdf'))
                .map((file, index) => {
                  return (
                    <Grid item xs={4} key={index}>
                      <Box
                        className='boxPreviewFile'
                        alignItems={'center'}
                        justifyContent='space-between'
                      >
                        <img src={file} alt='previewImage' />
                      </Box>
                    </Grid>
                  )
                })}
            </Grid>

            {data?.billPhoto && data?.billPhoto.includes('.pdf') && (
              <Grid container columns={12} marginTop={0} spacing={4}>
                <Grid item xs={12}>
                  <img
                    src={pdfImg}
                    alt='Billede'
                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                  />
                  <a href={data?.billPhoto} target='_blank' rel='noreferrer'>
                    <i>{t('billPdf')}</i>
                  </a>
                </Grid>
              </Grid>
            )}

            <Stack
              className='borderBottom'
              direction={'row'}
              alignItems='center'
              sx={{ my: 6 }}
              spacing={4}
            >
              <Icon name='icon-card' color={Colors.primary} />
              <Typography color={Colors.primary} variant='h3'>
                {t('tickets')}
              </Typography>
            </Stack>
            {data &&
              data?.cases?.map((ticket) => {
                return (
                  <Link key={ticket.caseId} to={`/ticket?id=${ticket.caseId}`}>
                    <Box className='boxTicket' sx={{ px: 0, py: 3 }}>
                      <Icon
                        name={ticket.repairShopId ? 'icon-buildings' : 'icon-house'}
                        color={Colors.secondary}
                      />
                      <Box>
                        <Typography display='block' color={Colors.primary}>
                          {ticket.subject}
                        </Typography>
                      </Box>
                      <Chip
                        sx={{ marginLeft: 'auto' }}
                        label={t(ticket.status)}
                        variant='outlined'
                        color={ticketClasses[ticket.status] as any}
                      />
                    </Box>
                  </Link>
                )
              })}
            {data && data.cases?.length === 0 && (
              <Box sx={{ mb: 6 }}>
                <Typography variant='body2'>{t('noData')}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default Detail
