import { Box, Breadcrumbs, Button, Chip, Container, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { AppBar, Icon, Loading } from '../../components'
import { DATE_FORMAT_FULL } from '../../components/DatePicker/DatePicker'
import {
  CustomerCaseApi,
  ECaseStatus,
  ERepairerCaseRequest,
  EServicePlace,
  FilterCaseModel,
} from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import { useTranslation } from 'react-i18next'
export const ticketClasses = {
  UnSent: 'warning',
  NewUnAssigned: 'warning',
  Pending: 'warning',
  Repairing: 'warning',
  Delayed: 'warning',
  PaymentWaiting: 'warning',
  Finished: 'success',
  Returned: 'success',
  Received: 'success',
  Closed: 'error',
  All: 'success',
}

function List() {
  const { t } = useTranslation()
  const [filter] = useState<FilterCaseModel>({
    caseRequest: ERepairerCaseRequest.All,
    caseStatus: ECaseStatus.All,
    servicePlace: EServicePlace.All,
    search: '',
    page: 0,
    limit: 20,
  })
  const { data, isLoading } = useQuery(['/api/customerCases/getList'], async () => {
    const res = await new CustomerCaseApi().apiCustomerCasesGet(filter)
    return res.data
  })
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('myTickets')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'lg'}>
        <Box justifyContent={'space-between'} display='flex' alignItems={'center'} sx={{ mt: 6 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link to='/'>Cykelmakker</Link>
            <Typography color='text.primary'>{t('myTickets')}</Typography>
          </Breadcrumbs>
          <Link to='/create-ticket'>
            <Button fullWidth variant='contained' size='small'>
              {t('createATicket')}
            </Button>
          </Link>
        </Box>
        {isLoading && <Loading />}
        {!isLoading && (
          <Box sx={{ mt: 6 }}>
            {data &&
              data?.data &&
              data.data.length > 0 &&
              data?.data.map((ticket) => {
                return (
                  <Link key={ticket.caseId} to={`/ticket?id=${ticket.caseId}`}>
                    <Box className='boxTicket'>
                      <Icon
                        name={
                          ticket.servicePlace === EServicePlace.AtRepairshop
                            ? 'icon-buildings'
                            : 'icon-house'
                        }
                        color={Colors.secondary}
                      />
                      <Box>
                        <Typography display='block' color={Colors.primary}>
                          {ticket.subject}
                        </Typography>
                        <Typography variant='caption'>
                          {moment.unix(ticket.lastUpdatedTime ?? 0).format(DATE_FORMAT_FULL)}
                        </Typography>
                      </Box>
                      <Chip
                        sx={{ marginLeft: 'auto' }}
                        label={t(ticket.status)}
                        variant='outlined'
                        color={ticketClasses[ticket.status] as any}
                      />
                    </Box>
                  </Link>
                )
              })}
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default List
