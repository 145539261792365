import { Box, Button, Container, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { AppBar } from '../../components'
import { updateSnackbarState } from '../../redux'
import { AccountApi, EMainContact, RegisterDto } from '../../services/Swagger/Services'
import { Colors } from '../../theme'
import { COUNTRIES } from '../../services/countries'
import Storage, { LOGIN_USER, USER_PROFILE } from '../../services/Storage'
import { usePlacesWidget } from 'react-google-autocomplete'
import { MAP_AUTO_COMPLETE_CONFIG } from '../../services/enum/enum'
import { useTranslation } from 'react-i18next'

const profileSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  fullName: yup.string().required(),
  address: yup.string().required(),
  lat: yup.number().required(),
  lng: yup.number().required(),
  mainContact: yup.string().required(),
  address2: yup.string(),
  city: yup.string(),
  postalCode: yup.string(),
  country: yup.string(),
  phoneNumber: yup.string(),
  avatar: yup.string(),
  language: yup.string(),
})
function SignUp() {
  const formRef = useRef<FormikProps<RegisterDto>>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [profile] = useState<RegisterDto>({
    fullName: '',
    address: '',
    address2: '',
    lat: 0,
    lng: 0,
    city: '',
    postalCode: '',
    country: '',
    phoneNumber: '',
    avatar: '',
    mainContact: EMainContact.Email,
    email: '',
    password: '',
  })
  const { ref } = usePlacesWidget({
    ...MAP_AUTO_COMPLETE_CONFIG,
    onPlaceSelected: (place) => {
      if (place.address_components && place.formatted_address && place.geometry) {
        const city = place.address_components?.find((add: any) =>
          add.types.includes('administrative_area_level_1'),
        )
        const country = place.address_components?.find((add: any) => add.types.includes('country'))
        formRef.current?.setFieldValue('city', city?.long_name)
        formRef.current?.setFieldValue('country', country?.short_name)
        formRef.current?.setFieldValue('address', place.formatted_address)
        formRef.current?.setFieldValue('lat', place.geometry.location.lat())
        formRef.current?.setFieldValue('lng', place.geometry.location.lng())
      } else {
        dispatch(
          updateSnackbarState({
            visible: true,
            message:
              'Oop, We could not find your address on the map. Make sure the address is correct',
            type: 'error',
          } as any),
        )
      }
    },
  })
  const onSubmit = async (values: RegisterDto) => {
    setIsSubmitting(true)
    try {
      const { data } = await new AccountApi().apiAccountsRegisterPost(values)
      Storage.setItem(LOGIN_USER, data)
      const response = await new AccountApi().apiAccountsCustomerProfileGet()
      Storage.setItem(USER_PROFILE, response.data)
      navigate('/')
    } catch (error: any) {
      dispatch(
        updateSnackbarState({
          visible: true,
          message: error.response.headers.status,
          type: 'error',
        } as any),
      )
    }
    setIsSubmitting(false)
  }
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('signUp')}</title>
      </Helmet>
      <AppBar title={t('signUp')} />
      <Container maxWidth={'sm'}>
        <Box sx={{ my: 12 }}>
          <Typography variant='h3' align='center'>
            {t('signUpTitle')}
          </Typography>
        </Box>
        <Formik
          innerRef={formRef}
          initialValues={profile}
          enableReinitialize={true}
          validationSchema={profileSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleSubmit, values, errors, isValid }) => (
            <>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption' className='required'>
                  {t('fullname')}
                </Typography>
                <TextField
                  value={values.fullName}
                  onChange={handleChange('fullName')}
                  fullWidth
                  variant='outlined'
                />
                {errors.fullName && (
                  <Typography className='textError'>{errors.fullName}</Typography>
                )}
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption' className='required'>
                  {t('email')}
                </Typography>
                <TextField
                  value={values.email}
                  onChange={handleChange('email')}
                  fullWidth
                  variant='outlined'
                />
                {errors.email && <Typography className='textError'>{errors.email}</Typography>}
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption' className='required'>
                  {t('password')}
                </Typography>
                <TextField
                  value={values.password}
                  onChange={handleChange('password')}
                  fullWidth
                  type='password'
                  variant='outlined'
                />
                {errors.password && (
                  <Typography className='textError'>{errors.password}</Typography>
                )}
              </Box>
              <Typography variant='h3' color={Colors.primary} sx={{ mb: 6, mt: 12 }}>
                {t('yourContact')}
              </Typography>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption' className='required'>
                  {t('address')}
                </Typography>
                <TextField
                  inputRef={ref}
                  value={values?.address}
                  onChange={handleChange('address')}
                  fullWidth
                  variant='outlined'
                />
              </Box>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption' className='required'>
                      {t('postalCode')}
                    </Typography>
                    <TextField
                      value={values?.postalCode}
                      onChange={handleChange('postalCode')}
                      fullWidth
                      variant='outlined'
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption'>{t('city')}</Typography>
                    <TextField
                      value={values?.city}
                      onChange={handleChange('city')}
                      fullWidth
                      variant='outlined'
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('country')}</Typography>
                <TextField
                  select
                  fullWidth
                  value={values?.country}
                  onChange={handleChange('country')}
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('phoneNumber')}</Typography>
                <TextField
                  value={values?.phoneNumber}
                  onChange={handleChange('phoneNumber')}
                  fullWidth
                  variant='outlined'
                />
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('howWeWillContactYou')}</Typography>
                <TextField
                  select
                  fullWidth
                  value={values?.mainContact}
                  onChange={handleChange('mainContact')}
                >
                  {Object.values(EMainContact).map((contact) => (
                    <MenuItem key={contact} value={contact}>
                      {contact}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ mb: 8 }}>
                <Button
                  fullWidth
                  disabled={isSubmitting || !isValid}
                  variant='contained'
                  onClick={() => handleSubmit()}
                >
                  {t('signUp')}
                </Button>
              </Box>
              <Typography sx={{ color: Colors.primary, mb: 8 }} align='center'>
                <Link to='/auth/login'>{t('backToLogin')}</Link>
              </Typography>
            </>
          )}
        </Formik>
      </Container>
    </Box>
  )
}

export default SignUp
