import { Box, Button, Container, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppBar, Icon } from '../../components'
import { updateSnackbarState } from '../../redux'
import Storage, { USER_PROFILE } from '../../services/Storage'
import {
  AccountApi,
  CustomerProfileUpdate,
  EMainContact,
  CustomerProfile,
} from '../../services/Swagger/Services'
import { COUNTRIES } from '../../services/countries'
import { Colors } from '../../theme'
import { usePlacesWidget } from 'react-google-autocomplete'
import { MAP_AUTO_COMPLETE_CONFIG } from '../../services/enum/enum'
import { useTranslation } from 'react-i18next'
import yup from '../../services/Translations/yup'
import Codes from '../../assets/codes.json'

const profileSchema = yup.object({
  fullName: yup.string().required(),
  address: yup.string().required(),
  lat: yup.number().required(),
  lng: yup.number().required(),
  mainContact: yup.string().required(),
  address2: yup.string(),
  city: yup.string(),
  country: yup.string(),
  phoneNumber: yup.string(),
  avatar: yup.string(),
  language: yup.string(),
})
function Profile() {
  const user: CustomerProfile = Storage.getItem(USER_PROFILE)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isSubmit, setIsSubmit] = useState(false)
  const {
    fullName,
    address,
    address2,
    lat,
    lng,
    city,
    postalCode,
    country,
    phoneNumber,
    avatar,
    mainContact,
    language,
  } = user
  const [profile] = useState<CustomerProfileUpdate>({
    fullName: fullName ?? '',
    address: address ?? '',
    address2,
    lat: lat ?? 0,
    lng: lng ?? 0,
    city,
    postalCode,
    country,
    phoneNumber,
    avatar,
    mainContact,
    language,
  })
  const formRef = useRef<FormikProps<CustomerProfileUpdate>>(null)
  const { ref } = usePlacesWidget({
    ...MAP_AUTO_COMPLETE_CONFIG,
    onPlaceSelected: (place) => {
      if (place.address_components && place.formatted_address && place.geometry) {
        const city = place.address_components?.find((add: any) =>
          add.types.includes('administrative_area_level_1'),
        )
        const country = place.address_components?.find((add: any) => add.types.includes('country'))
        formRef.current?.setFieldValue('city', city?.long_name)
        formRef.current?.setFieldValue('country', country?.short_name)
        formRef.current?.setFieldValue('address', place.formatted_address)
        formRef.current?.setFieldValue('lat', place.geometry.location.lat())
        formRef.current?.setFieldValue('lng', place.geometry.location.lng())
      } else {
        dispatch(
          updateSnackbarState({
            visible: true,
            message:
              'Oop, We could not find your address on the map. Make sure the address is correct',
            type: 'error',
          } as any),
        )
      }
    },
  })
  const onSubmit = async (values: CustomerProfileUpdate) => {
    setIsSubmit(true)
    try {
      await new AccountApi().apiAccountsUpdateCustomerProfilePut(values)
      const response = await new AccountApi().apiAccountsCustomerProfileGet()
      Storage.setItem(USER_PROFILE, response.data)
      dispatch(
        updateSnackbarState({
          visible: true,
          message: 'Update your profile successfully',
          type: 'success',
        } as any),
      )
    } catch (ex) {
      dispatch(
        updateSnackbarState({
          visible: true,
          message: 'Error when update your profile, please check your profile information',
          type: 'error',
        } as any),
      )
    }
    setIsSubmit(false)
  }
  return (
    <Box>
      <Helmet>
        <title>Cykelmakker - {t('userProfile')}</title>
      </Helmet>
      <AppBar />
      <Container maxWidth={'md'}>
        <Stack
          direction='row'
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            my: 6,
          }}
        >
          <Box sx={{ width: 30 }}>
            <Link to='/'>
              <Icon name='icon-arrow-left' color='white' />
            </Link>
          </Box>
          <Box>
            <Typography variant='h3'>{t('editProfile')}</Typography>
          </Box>
          <Box sx={{ width: 30 }}></Box>
        </Stack>
        <Formik
          innerRef={formRef}
          initialValues={profile}
          enableReinitialize={true}
          validationSchema={profileSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, setFieldValue, handleSubmit, values, errors, isValid }) => (
            <>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('email')}</Typography>
                <TextField
                  value={user.email}
                  fullWidth
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('language')}</Typography>
                <TextField
                  value={t('languages.da')}
                  fullWidth
                  variant='outlined'
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption' className='required'>
                  {t('fullname')}
                </Typography>
                <TextField
                  value={values.fullName}
                  onChange={handleChange('fullName')}
                  fullWidth
                  variant='outlined'
                />
                {errors.fullName && (
                  <Typography className='textError'>{errors.fullName}</Typography>
                )}
              </Box>
              <Typography variant='h3' color={Colors.primary} sx={{ mb: 6, mt: 12 }}>
                {t('yourContact')}
              </Typography>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption' className='required'>
                  {t('address')}
                </Typography>
                <TextField
                  inputRef={ref}
                  value={values?.address}
                  onChange={handleChange('address')}
                  fullWidth
                  variant='outlined'
                />
                {errors.address && <Typography className='textError'>{errors.address}</Typography>}
              </Box>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption'>{t('postalCode')}</Typography>
                    <TextField
                      value={values?.postalCode}
                      onChange={(event) => {
                        setFieldValue('postalCode', event.target.value)
                        const findIndex = Codes.findIndex((x) => x.code == event.target.value)
                        if (findIndex != -1) {
                          setFieldValue('city', Codes[findIndex].city)
                        } else {
                          setFieldValue('city', '')
                        }
                      }}
                      fullWidth
                      variant='outlined'
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ mb: 6 }}>
                    <Typography variant='caption'>{t('city')}</Typography>
                    <TextField
                      value={values?.city}
                      onChange={handleChange('city')}
                      fullWidth
                      variant='outlined'
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('country')}</Typography>
                <TextField
                  select
                  fullWidth
                  value={values?.country}
                  onChange={handleChange('country')}
                >
                  {COUNTRIES.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('phoneNumber')}</Typography>
                <TextField
                  value={values?.phoneNumber}
                  onChange={handleChange('phoneNumber')}
                  fullWidth
                  variant='outlined'
                />
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant='caption'>{t('howWeWillContactYou')}</Typography>
                <TextField
                  select
                  fullWidth
                  value={values?.mainContact}
                  onChange={handleChange('mainContact')}
                >
                  {Object.values(EMainContact).map((contact) => (
                    <MenuItem key={contact} value={contact}>
                      {contact}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box sx={{ mb: 8 }}>
                <Button
                  fullWidth
                  disabled={isSubmit || !isValid}
                  variant='contained'
                  onClick={() => handleSubmit()}
                >
                  {t('editProfile')}
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </Container>
    </Box>
  )
}

export default Profile
